import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import logo from "../images/EMLOGO.png";
import axios from 'axios';

const Charter = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        enquiry: ""
    });

    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('/sendCharterEnquiry', formData);
          if (response.status === 200) {
            alert('Thank you for your enquiry! We will get back to you soon.');
          }
        } catch (error) {
          console.error('Error sending enquiry:', error);
          alert('Failed to send your enquiry. Please try again later.');
        }
      };

    return (
        <div className="w-[100%] listings-body mx-auto bg-em-skin text-sans">
            {/* Navbar */}
            <nav className="flex items-center justify-between flex-wrap p-6 z-20 fixed top-0 left-0 right-0 bg-opacity-50 backdrop-blur-md shadow-bottom-stroke opacity-1">
      <div className="flex items-center flex-shrink-0 text-em-brown mr-6 lg:mr-8">
                <a href="/" className="font-sans text-3xl font-bold tracking-extra-wide">
                <img src={logo} alt="Elite Marine Logo" className="w-10"></img>
                </a>
                </div>
        <div className="block lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
          >
            <svg className={`fill-current h-3 w-3 ${isOpen ? 'hidden' : 'block'}`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            <svg className={`fill-current h-3 w-3 ${isOpen ? 'block' : 'hidden'}`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
        <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? 'block' : 'hidden'}`}>
          <div className="text-1.5xl lg:flex-grow">
            <a href="/" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">HOME</a>
            <a href="/Charter" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">CHARTER</a>
            <a href="/Listings" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">BROKERAGE</a>
            <a href="/about" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">ABOUT</a>
            <a href="/faq" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">FAQ</a>
          </div>
          <a href="/enquire">
          <div>
            <button className="inline-flex text-1.5xl items-center border-em-brown py-1 text-em-brown underline">ENQUIRE</button>
          </div>
          </a>
        </div>
      
            </nav>

            {/* Main Section */}
            <div className="px-8 py-20 text-center">
                <h1 className="text-4xl font-bold font-sans text-em-brown mb-10 mt-20">CHARTERING ENQUIRIES</h1>
                <h2 className="text-2xl font-sans text-em-brown mb-10">For chartering enquiries, please fill in the form below and we will get back to you as soon as possible.</h2>
                <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white p-6 shadow-md rounded-lg">
                    <div className="mb-4">
                        <label htmlFor="name" className="block font-sans text-left text-sm font-medium text-gray-700">
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="mt-1 px-4 py-2 block w-full rounded-md  border-gray-300 shadow-sm focus:to-em-brown focus:to-em-brown sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block font-sans text-left text-sm font-medium text-gray-700">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1  px-4 py-2  block w-full font-sans rounded-md border-gray-300 shadow-sm focus:to-em-brown focus:to-em-brown sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="enquiry" className="block font-sans text-left text-sm font-medium text-gray-700">
                            Enquiry
                        </label>
                        <textarea
                            id="enquiry"
                            name="enquiry"
                            value={formData.enquiry}
                            onChange={handleChange}
                            rows="4"
                            className="mt-1 block w-full  px-4 py-2  font-sans rounded-md border-gray-300 shadow-sm focus:to-em-brown focus:to-em-brown sm:text-sm"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 font-sans bg-em-brown text-white rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:to-em-brown focus:to-em-brown"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Charter;
