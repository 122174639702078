import React, { useState } from 'react';
import logo from '../images/EMLOGO.png';
import axios from 'axios';

function Enquire() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [enquiry, setEnquiry] = useState('');
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post(
                'https://us-central1-elite-marine-web-development.cloudfunctions.net/api/sendEmail',
                {
                    name: name,
                    to: email,
                    subject: "General Enquiry",
                    text: enquiry,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (response.status === 200) {
                alert('Thank you for your enquiry! We will get back to you soon.');
            } else {
                alert(`Error: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to send enquiry. Please try again later.');
        }
    };
    


    return (
        <div className="min-h-screen ">
            {/* Navbar */}
            <nav className="flex items-center justify-between flex-wrap p-6 z-20 fixed top-0 left-0 right-0 bg-opacity-50 backdrop-blur-md shadow-bottom-stroke opacity-1">
      <div className="flex items-center flex-shrink-0 text-em-brown mr-6 lg:mr-8">
                <a href="/" className="font-sans text-3xl font-bold tracking-extra-wide">
                <img src={logo} alt="Elite Marine Logo" className="w-10"></img>
                </a>
                </div>
        <div className="block lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
          >
            <svg className={`fill-current h-3 w-3 ${isOpen ? 'hidden' : 'block'}`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            <svg className={`fill-current h-3 w-3 ${isOpen ? 'block' : 'hidden'}`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
        <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? 'block' : 'hidden'}`}>
          <div className="text-1.5xl lg:flex-grow">
            <a href="/" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">HOME</a>
            <a href="/Charter" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">CHARTER</a>
            <a href="/Listings" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">BROKERAGE</a>
            <a href="/about" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">ABOUT</a>
            <a href="/faq" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">FAQ</a>
          </div>
          <a href="/enquire">
          <div>
            <button className="inline-flex text-1.5xl items-center border-em-brown py-1 text-em-brown underline">ENQUIRE</button>
          </div>
          </a>
        </div>
      
      </nav>
       

            {/* Enquire Form Section */}
            <section className="max-w-2xl mx-auto mt-32 p-8  rounded-md shadow-lg bg-white mb-96">
                <h1 className="text-2xl font-semibold  text-center">
                Thank you so much for contacting us!
                </h1>
                <h2 className="text-lg font-medium mb-11 text-center">Please allow us 2-3 working days to follow up.</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label htmlFor="name" className="block text-gray-700 mb-2">
                            Name:
                        </label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full px-4 py-2 border rounded-md focus:ring focus:outline-none"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-gray-700 mb-2">
                            Email:
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-4 py-2 border rounded-md focus:ring focus:outline-none"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="enquiry" className="block text-gray-700 mb-2">
                            Enquiry:
                        </label>
                        <textarea
                            id="enquiry"
                            value={enquiry}
                            onChange={(e) => setEnquiry(e.target.value)}
                            className="w-full px-4 py-2 border rounded-md focus:ring focus:outline-none"
                            rows="4"
                            required
                        ></textarea>
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="bg-em-brown text-white px-6 py-2 rounded hover:bg-em-select"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </section>

            <div className="segment-5 bg-em-brown text-white py-10 mb-0">
                
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
            <div className="flex flex-wrap justify-between font-sans">
                
                      <div className="logo mb-8 w-full md:w-1/4">
                        {/* <img src="path-to-logo.png" alt="Elite Marine Logo" className="w-40 mb-4"> */}
                        <p className="text-sm leading-tight">Luxury Yachts & Marine Services</p>
                      </div>

  
                      <div className="quick-links mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Quick Links</h2>
                        <ul className="space-y-2">
                          <li><a href="#" className="hover:text-gray-400">Home</a></li>
                          <li><a href="#" className="hover:text-gray-400">Charter</a></li>
                          <li><a href="#" className="hover:text-gray-400">Brokerage</a></li>
                          <li><a href="#" className="hover:text-gray-400">Sell</a></li>
                          <li><a href="#" className="hover:text-gray-400">About</a></li>
                          <li><a href="#" className="hover:text-gray-400">FAQ</a></li>
                        </ul>
                      </div>

       
                      <div className="singapore mb-8 w-full md:w-1/4">
                        <h2 className=" font-bold text-lg mb-4">Singapore Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+6580536058" className="hover:text-gray-400">+65 8053 6058</a><br></br>
                          <a href="tel:+6583743641" className="hover:text-gray-400">+65 8374 3641</a><br></br>
                          29 Benoi Road, Pioneer Lot, <br></br> Singapore 629922
                        </p>
                      </div>

        
                      <div className="italy mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Italy Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+393514968131" className="hover:text-gray-400">+39 351 496 8131</a><br></br>
                          Via del Parco Margherita 24, <br></br> 80121 Napoli NA, Italy
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-8">
                    <p className="text-sm">&copy; 2024 Elite Marine. All rights reserved.</p>
                  </div>
            
        
            </div>
            
            

        </div>
    );
}

export default Enquire;
