import React, { useState } from 'react';

import logo from '../images/EMLOGO.png';

const faqData = [
  {
    question: 'What we do.',
    answer:
      'We connect buyers with sellers, negotiate deals, handle paperwork, and ensure smooth transactions so that you can rest at home knowing that everything is alright.',
  },
  {
    question: 'How do I buy a yacht through a brokerage?',
    answer:
      'Buying a yacht through a brokerage is simple. You can browse our listings online or contact us directly with your specifications. We’ll guide you through the process, from finding the right yacht to closing the deal.',
  },
  {
    question: 'What types of yachts do you sell?',
    answer:
      'We offer a wide range of yachts, including motor yachts, sailing yachts, and mega yachts. Whether you’re looking for a sleek cruiser or a spacious superyacht, we have options to suit every taste and budget.',
  },
  {
    question: 'Do you assist with yacht financing and insurance?',
    answer:
      'Yes, we can help you secure financing and insurance for your yacht purchase. We work with trusted lenders and insurance providers to ensure you get the best rates and coverage options.',
  },
  {
    question: 'How are yacht prices determined?',
    answer:
      'Yacht prices vary depending on factors such as size, age, condition, brand, and features. Our experienced brokers can provide you with market insights and help you negotiate the best price for your desired yacht.',
  },
  {
    question: 'Can I sell my yacht through your brokerage?',
    answer:
      'Absolutely! We welcome yacht owners looking to sell their vessels. Our team will work with you to market your yacht effectively, attract qualified buyers, and secure the best possible sale price.',
  },
  {
    question: 'Why should I choose your brokerage for my yacht needs?',
    answer:
      'With industry-low commissions, personalized service, and commitment to client satisfaction, you can trust us to deliver exceptional results and exceed your expectations every step of the way.',
  },
];

function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="min-h-screen mt-36">
      <nav className="flex items-center justify-between flex-wrap p-6 z-20 fixed top-0 left-0 right-0 bg-opacity-50 backdrop-blur-md shadow-bottom-stroke opacity-1">
      <div className="flex items-center flex-shrink-0 text-em-brown mr-6 lg:mr-8">
                <a href="/" className="font-sans text-3xl font-bold tracking-extra-wide">
                <img src={logo} alt="Elite Marine Logo" className="w-10"></img>
                </a>
                </div>
        <div className="block lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
          >
            <svg className={`fill-current h-3 w-3 ${isOpen ? 'hidden' : 'block'}`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            <svg className={`fill-current h-3 w-3 ${isOpen ? 'block' : 'hidden'}`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
        <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? 'block' : 'hidden'}`}>
          <div className="text-1.5xl lg:flex-grow">
            <a href="/" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">HOME</a>
            <a href="/Charter" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">CHARTER</a>
            <a href="/Listings" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">BROKERAGE</a>
            <a href="/about" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">ABOUT</a>
            <a href="/faq" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">FAQ</a>
          </div>
          <a href="/enquire">
          <div>
            <button className="inline-flex text-1.5xl items-center border-em-brown py-1 text-em-brown underline">ENQUIRE</button>
          </div>
          </a>
        </div>
      
      </nav>


      <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">
        Frequently Asked Questions
      </h1>
      <div className="max-w-3xl mx-auto mb-64">
        {faqData.map((faq, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-md mb-4 overflow-hidden"
          >
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full text-left p-4 flex justify-between items-center bg-em-brown text-white hover:bg-em-select"
            >
              <span className="text-lg font-medium">{faq.question}</span>
              <svg
                className={`w-6 h-6 transform transition-transform duration-300 ${
                  openIndex === index ? 'rotate-180' : 'rotate-0'
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {openIndex === index && (
              <div className="p-4 text-gray-700 border-t">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>

      <div className="segment-5 bg-em-brown text-white py-10 w-100%">
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
            <div className="flex flex-wrap justify-between font-sans">
                
                      <div className="logo mb-8 w-full md:w-1/4">
                        {/* <img src="path-to-logo.png" alt="Elite Marine Logo" className="w-40 mb-4"> */}
                        <p className="text-sm leading-tight">Luxury Yachts & Marine Services</p>
                      </div>

  
                      <div className="quick-links mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Quick Links</h2>
                        <ul className="space-y-2">
                          <li><a href="#" className="hover:text-gray-400">Home</a></li>
                          <li><a href="#" className="hover:text-gray-400">Charter</a></li>
                          <li><a href="#" className="hover:text-gray-400">Brokerage</a></li>
                          <li><a href="#" className="hover:text-gray-400">Sell</a></li>
                          <li><a href="#" className="hover:text-gray-400">About</a></li>
                          <li><a href="#" className="hover:text-gray-400">FAQ</a></li>
                        </ul>
                      </div>

       
                      <div className="singapore mb-8 w-full md:w-1/4">
                        <h2 className=" font-bold text-lg mb-4">Singapore Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+6580536058" className="hover:text-gray-400">+65 8053 6058</a><br></br>
                          <a href="tel:+6583743641" className="hover:text-gray-400">+65 8374 3641</a><br></br>
                          29 Benoi Road, Pioneer Lot, <br></br> Singapore 629922
                        </p>
                      </div>

        
                      <div className="italy mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Italy Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+393514968131" className="hover:text-gray-400">+39 351 496 8131</a><br></br>
                          Via del Parco Margherita 24, <br></br> 80121 Napoli NA, Italy
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-8">
                    <p className="text-sm">&copy; 2024 Elite Marine. All rights reserved.</p>
                  </div>
            
        
      </div>
    </div>
  );
}

export default FAQ;
